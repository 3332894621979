<template>
    <v-container fluid v-if="$store.state.user.components.includes(73)">
        <v-card>
            <v-card-text>
                <v-tabs v-model="tab">
                    <v-tab v-if="$store.state.user.components.includes(74)">Users</v-tab>
                    <v-tab v-if="$store.state.user.components.includes(75)">Roles & Access</v-tab>

                    <v-tab-item v-if="$store.state.user.components.includes(74)">
                        <crud model="User" :headers="user_headers" label="Users">
                            <template v-slot:field.npi="{values,on,bind}">
                                <v-text-field
                                    v-bind="bind"
                                    v-on="on"
                                    v-mask="['###-###-####']"
                                    :class="values.is_provider ? 'required': null"
                                    :rules="values.is_provider ? [(val)=>(val && val.length==12) ||'Invalid'] : []"
                                ></v-text-field>
                            </template>
                        </crud>
                    </v-tab-item>
                    <v-tab-item v-if="$store.state.user.components.includes(75)">
                        <v-row>
                            <v-col cols="12" lg="5">
                                <crud
                                    model="UserRole"
                                    :headers="role_headers"
                                    label="User Roles"
                                    show-select
                                    single-select
                                    v-model="selectedRole"
                                ></crud>
                            </v-col>
                            <!--
                                <v-col cols="12" lg="7">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <td>Node</td>
                                                <td>Can View</td>
                                                <td>Can Insert</td>
                                                <td>Can Update</td>
                                                <td>Can Delete</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="node in nodes" :key="node.id">
                                                <td>{{node.name}}</td>
                                                <td>
                                                    <v-checkbox
                                                        dense
                                                        hide-details="auto"
                                                        v-model="node.can"
                                                        label="View"
                                                        value="view"
                                                    ></v-checkbox>
                                                </td>
                                                <td>
                                                    <v-checkbox
                                                        v-model="node.can"
                                                        dense
                                                        hide-details="auto"
                                                        label="Insert"
                                                        value="insert"
                                                    ></v-checkbox>
                                                </td>
                                                <td>
                                                    <v-checkbox
                                                        v-model="node.can"
                                                        dense
                                                        hide-details="auto"
                                                        label="Update"
                                                        value="update"
                                                    ></v-checkbox>
                                                </td>
                                                <td>
                                                    <v-checkbox
                                                        v-model="node.can"
                                                        dense
                                                        hide-details="auto"
                                                        label="Delete"
                                                        value="delete"
                                                    ></v-checkbox>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                            -->

                            <v-col
                                v-if="selectedRole.length<=0"
                                cols="12"
                                lg="7"
                                class="text-center"
                            >
                                <v-alert dense outlined type="warning">Please select a Role!</v-alert>
                            </v-col>

                            <v-col v-else cols="12" lg="7">
                                <v-toolbar dense elevation="1">
                                    <v-toolbar-title>Role Settings ({{selectedRole[0].name}})</v-toolbar-title>
                                    <v-spacer />
                                    <v-toolbar-items>
                                        <v-fab-transition>
                                            <v-btn
                                                v-if="componentHasDifference"
                                                @click="saveValues()"
                                                color="red"
                                                outlined
                                                :disabled="components_loading"
                                                class="float-right"
                                            >
                                                <v-icon>mdi-database</v-icon>
                                                <template v-if="components_loading">... Saving ...</template>
                                                <template v-else>Save Changes</template>
                                            </v-btn>
                                        </v-fab-transition>
                                    </v-toolbar-items>
                                </v-toolbar>
                                <v-progress-linear v-if="components_loading" indeterminate striped></v-progress-linear>
                                <v-treeview dense hoverable :items="componentsTree">
                                    <template v-slot:label="{item}">
                                        <v-row dense>
                                            <v-col cols="auto">
                                                <span class="text-subtitle-1">{{item.name}}</span>
                                            </v-col>
                                            <v-col cols="auto">
                                                <span
                                                    class="text-caption font-italic"
                                                >{{item.description}}</span>
                                            </v-col>
                                        </v-row>
                                    </template>
                                    <template v-slot:append="{item}">
                                        <v-switch
                                            dense
                                            hide-details="auto"
                                            inset
                                            label="On"
                                            class="mt-0"
                                            v-model="item.value"
                                            :disabled="components_loading"
                                        ></v-switch>
                                    </template>
                                </v-treeview>
                                <!-- 
                                   <v-data-table
                                    :items="components"
                                    :headers="componentHeaders"
                                    :loading="components_loading"
                                >
                                    <template v-slot:item.features="{item}">
                                        <v-row>
                                            <v-col
                                                v-for="feature in item.features"
                                                :key="feature.id"
                                            >
                                                <v-radio-group
                                                    :label="feature.name"
                                                    dense
                                                    v-model="feature.values[0]"
                                                >
                                                    <v-radio
                                                        v-for="(option, ko) in feature.options"
                                                        :key="ko"
                                                        :value="option.value"
                                                        :label="option.name"
                                                    ></v-radio>
                                                </v-radio-group>
                                            </v-col>
                                        </v-row>
                                    </template>
                                </v-data-table>-->
                            </v-col>
                        </v-row>
                    </v-tab-item>
                </v-tabs>
            </v-card-text>
        </v-card>
        <v-snackbar v-model="show_snackbar" timeout="2500" top right color="success" dark>
            <h3 v-if="selectedRole[0]">
                <v-icon>mdi-database-check</v-icon>
                {{selectedRole[0].name}} Features Saved!
            </h3>
        </v-snackbar>
    </v-container>
</template>
<script>
import gql from "graphql-tag";
import crud from "../../components/CRUD.vue";
import { mask } from "vue-the-mask";
export default {
    components: {
        crud,
    },
    directives: {
        mask,
    },
    data() {
        return {
            tab: 0,
            nodes: [],
            selectedRole: [],
            role_headers: [
                {
                    value: "id",
                    text: "ID",
                    visible: false,
                    editable: false,
                    isId: true,
                },
                {
                    value: "name",
                    text: "Name",
                    mandatory: true,
                    searchable: true,
                },
            ],
            npiRequired: false,
            user_headers: [
                {
                    value: "id",
                    text: "ID",
                    visible: false,
                    editable: false,
                    isId: true,
                },
                {
                    value: "name",
                    text: "Name",
                    editable: false,
                },
                {
                    value: "first_name",
                    text: "First Name",
                    mandatory: true,
                    searchable: true,
                    visible: false,
                },
                {
                    value: "middle_name",
                    text: "Middle Name",
                    searchable: true,
                    visible: false,
                },
                {
                    value: "last_name",
                    text: "Last Name",
                    mandatory: true,
                    searchable: true,
                    visible: false,
                },
                { value: "username", text: "Username", mandatory: true },
                { value: "password", text: "Password", visible: false },
                {
                    value: "email",
                    text: "Email",
                    searchable: true,
                    visible: false,
                },
                {
                    value: "phone",
                    text: "Phone",
                    searchable: true,
                    visible: false,
                },
                {
                    value: "Facility.name",
                    text: "Facility",
                    type: "select",
                    mandatory: true,
                    model: "Facility",
                    modelId: "facility_id",
                    modelFields: { value: "id", text: "name" },
                    conditions: [
                        { id: { in: this.$store?.state?.user?.payload?.ls } },
                    ],
                },
                {
                    value: "Role.name",
                    text: "Role",
                    type: "select",
                    mandatory: true,
                    model: "UserRole",
                    modelId: "role_id",
                    modelFields: { value: "id", text: "name" },
                },
                {
                    value: "status",
                    text: "Status",
                    type: "select",
                    mandatory: true,
                    items: ["Active", "Inactive", "Pending"],
                },
                {
                    value: "is_provider",
                    text: "Provider",
                    type: "switch",
                    visible: true,
                },
                {
                    value: "npi",
                    text: "NPI",
                    type: "text",
                    mask: "####-###-###",
                    mandatory: true,
                },
            ],
            components: [],
            componentsMap: {},
            componentsDiff: [],
            components_loading: false,
            componentHeaders: [
                { value: "name", text: "Component" },
                { value: "type", text: "Type" },
                { value: "description", text: "Description" },
                { value: "features", text: "Features" },
            ],
            show_snackbar: false,
            componentsTree: [],
        };
    },
    computed: {
        componentHasDifference() {
            return (
                JSON.stringify(this.componentsDiff) !=
                JSON.stringify(this.componentsTree)
            );
        },
    },
    watch: {
        selectedRole(n) {
            if (this.components.length <= 0) this.getComponents();
            this.getValues(n[0].id);
        },
    },
    methods: {
        getChildren(parentID) {
            let tree = [];
            this.components.forEach((c) => {
                if (c.parent_id == parentID) {
                    this.componentsMap[c.id] = {
                        ...c,
                        children: this.getChildren(c.id),
                    };
                    tree.push(this.componentsMap[c.id]);
                }
            });
            return tree;
        },
        getComponents() {
            this.components_loading = true;
            this.$apollo
                .query({
                    query: gql`
                        {
                            SystemComponents(
                                where: [{ deleted: { is: null } }]
                            ) {
                                id
                                parent_id
                                name
                                description
                            }
                        }
                    `,
                    fetchPolicy: "no-cache",
                })
                .then((res) => {
                    if (res.data.SystemComponents instanceof Array) {
                        this.components = res.data.SystemComponents;

                        this.componentsMap = {};
                        this.componentsTree = this.getChildren(null);
                    }
                })
                .finally(() => {
                    this.components_loading = false;
                });
        },
        getValues(roleID) {
            this.components_loading = true;
            Object.keys(this.componentsMap).forEach((k) => {
                this.$set(this.componentsMap[k], "value", false);
                this.$set(this.componentsMap[k], "vid", undefined);
            });
            this.componentsDiff = JSON.parse(
                JSON.stringify(this.componentsTree)
            );
            this.$apollo
                .query({
                    query: gql`
                        {
                            RoleComponents(where: [{ deleted: { is: null } }, { role_id: { eq: ${roleID} } }]) {
                                id
                                value
                                component_id
                                role_id
                            }
                        }
                    `,
                    fetchPolicy: "no-cache",
                })
                .then((res) => {
                    if (res.data.RoleComponents instanceof Array) {
                        res.data.RoleComponents.forEach((v) => {
                            if (this.componentsMap[v.component_id]) {
                                this.$set(
                                    this.componentsMap[v.component_id],
                                    "value",
                                    v.value
                                );
                                this.$set(
                                    this.componentsMap[v.component_id],
                                    "vid",
                                    v.id
                                );
                            }
                        });
                    }
                })
                .finally(() => {
                    this.componentsDiff = JSON.parse(
                        JSON.stringify(this.componentsTree)
                    );
                    this.components_loading = false;
                });
        },
        saveValues() {
            this.components_loading = true;

            let components = [];
            Object.keys(this.componentsMap).forEach((k) => {
                components.push({
                    id: this.componentsMap[k].vid,
                    role_id: this.componentsMap[k].role_id
                        ? this.componentsMap[k].role_id
                        : this.selectedRole[0].id,
                    component_id: this.componentsMap[k].id,
                    value: this.componentsMap[k].value,
                });
            });

            /*Object.keys(this.componentsMap).forEach((k) => {
                this.$set(this.componentsMap[k], "value", false);
                this.$set(this.componentsMap[k], "vid", undefined);
            });*/

            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation ($data: [RoleComponentSave]!) {
                            RoleComponentsSave(data: $data) {
                                id
                                role_id
                                component_id
                                value
                            }
                        }
                    `,
                    variables: {
                        data: components,
                    },
                })
                .catch(() => {
                    this.getValues(this.selectedRole[0].id);
                })
                .then((res) => {
                    if (res.data.RoleComponentsSave instanceof Array) {
                        res.data.RoleComponentsSave.forEach((v) => {
                            if (this.componentsMap[v.component_id]) {
                                this.$set(
                                    this.componentsMap[v.component_id],
                                    "value",
                                    v.value
                                );
                                this.$set(
                                    this.componentsMap[v.component_id],
                                    "vid",
                                    v.id
                                );
                            }
                        });
                    }
                    this.show_snackbar = true;
                })
                .finally(() => {
                    this.componentsDiff = JSON.parse(
                        JSON.stringify(this.componentsTree)
                    );
                    this.components_loading = false;
                });
        },
    },
    mounted() {
        this.getComponents();
    },
};
</script>
