var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$store.state.user.components.includes(73))?_c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-card-text',[_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[(_vm.$store.state.user.components.includes(74))?_c('v-tab',[_vm._v("Users")]):_vm._e(),(_vm.$store.state.user.components.includes(75))?_c('v-tab',[_vm._v("Roles & Access")]):_vm._e(),(_vm.$store.state.user.components.includes(74))?_c('v-tab-item',[_c('crud',{attrs:{"model":"User","headers":_vm.user_headers,"label":"Users"},scopedSlots:_vm._u([{key:"field.npi",fn:function(ref){
var values = ref.values;
var on = ref.on;
var bind = ref.bind;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:(['###-###-####']),expression:"['###-###-####']"}],class:values.is_provider ? 'required': null,attrs:{"rules":values.is_provider ? [function (val){ return (val && val.length==12) ||'Invalid'; }] : []}},'v-text-field',bind,false),on))]}}],null,false,627090883)})],1):_vm._e(),(_vm.$store.state.user.components.includes(75))?_c('v-tab-item',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"5"}},[_c('crud',{attrs:{"model":"UserRole","headers":_vm.role_headers,"label":"User Roles","show-select":"","single-select":""},model:{value:(_vm.selectedRole),callback:function ($$v) {_vm.selectedRole=$$v},expression:"selectedRole"}})],1),(_vm.selectedRole.length<=0)?_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","lg":"7"}},[_c('v-alert',{attrs:{"dense":"","outlined":"","type":"warning"}},[_vm._v("Please select a Role!")])],1):_c('v-col',{attrs:{"cols":"12","lg":"7"}},[_c('v-toolbar',{attrs:{"dense":"","elevation":"1"}},[_c('v-toolbar-title',[_vm._v("Role Settings ("+_vm._s(_vm.selectedRole[0].name)+")")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-fab-transition',[(_vm.componentHasDifference)?_c('v-btn',{staticClass:"float-right",attrs:{"color":"red","outlined":"","disabled":_vm.components_loading},on:{"click":function($event){return _vm.saveValues()}}},[_c('v-icon',[_vm._v("mdi-database")]),(_vm.components_loading)?[_vm._v("... Saving ...")]:[_vm._v("Save Changes")]],2):_vm._e()],1)],1)],1),(_vm.components_loading)?_c('v-progress-linear',{attrs:{"indeterminate":"","striped":""}}):_vm._e(),_c('v-treeview',{attrs:{"dense":"","hoverable":"","items":_vm.componentsTree},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('span',{staticClass:"text-subtitle-1"},[_vm._v(_vm._s(item.name))])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('span',{staticClass:"text-caption font-italic"},[_vm._v(_vm._s(item.description))])])],1)]}},{key:"append",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{staticClass:"mt-0",attrs:{"dense":"","hide-details":"auto","inset":"","label":"On","disabled":_vm.components_loading},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})]}}],null,false,339902602)})],1)],1)],1):_vm._e()],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":"2500","top":"","right":"","color":"success","dark":""},model:{value:(_vm.show_snackbar),callback:function ($$v) {_vm.show_snackbar=$$v},expression:"show_snackbar"}},[(_vm.selectedRole[0])?_c('h3',[_c('v-icon',[_vm._v("mdi-database-check")]),_vm._v(" "+_vm._s(_vm.selectedRole[0].name)+" Features Saved! ")],1):_vm._e()])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }